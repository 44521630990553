import { url } from './utils/urls'

export const URLS = {
  auth: {
    authCallback: url('/auth-callback/'),
    login: url('/login/'),
  },
  account: {
    logout: url('/account/logout/'),
  },
  dashboard: {
    index: url('/'),
  },
  serviceManagement: {
    key: url('/service-management/'),
    circuits: {
      list: url(
        '/service-management/circuits/:action?/:id?/:childUrl?/:childAction?/:childId?/'
      ),
    },
    devicesAndContracts: {
      key: url('/service-management/devices-and-contracts/'),
      devices: {
        list: url(
          '/service-management/devices-and-contracts/devices/:action?/:id?/:childUrl?/:childAction?/:childId?/'
        ),
      },
      maintenanceContracts: {
        list: url(
          '/service-management/devices-and-contracts/maintenance-contracts/:action?/:id?/:childUrl?/:childAction?/:childId?/'
        ),
      },
    },
    locations: {
      list: url('/service-management/locations/:action?/:id?/'),
    },
    services: {
      list: url('/service-management/services/:action?/:id?/'),
    },
    mobileVoice: {
      key: url('/service-management/mobile-voice/'),
      simCards: {
        list: url('/service-management/mobile-voice/sim-cards/:action?/:id?/'),
      },
    },
    mobile: {
      key: url('/service-management/mobile/'),
      cellularSIMCards: {
        list: url(
          '/service-management/mobile/cellular-sim-cards/:action?/:id?/'
        ),
      },
      cellularSIMCardChangeLogs: {
        list: url('/service-management/mobile/cellular-sim-card-change-logs/'),
      },
    },
    managedServices: {
      list: url('/service-management/managed-services/:action?/:id?/'),
    },
  },
  orderManagement: {
    key: url('/order-management/'),
    orders: {
      list: url(
        '/order-management/orders/:action?/:id?/:childUrl?/:childAction?/:childId?/'
      ),
    },
    workflows: {
      index: url('/order-management/workflows/'),
    },
  },
  clientManagement: {
    key: url('/client-management/'),
    companies: {
      list: url(
        '/client-management/companies/:action?/:id?/:childUrl?/:childAction?/:childId?/'
      ),
    },
    contacts: {
      list: url('/client-management/contacts/:action?/:id?/'),
    },
  },
  changeManagement: {
    key: url('/change-management/'),
    changes: {
      list: url('/change-management/changes/:action?/:id?/'),
    },
    requests: {
      preview: url('/change-management/preview-request/:id/'),
      list: url('/change-management/requests/:action?/:id?/'),
    },
  },
  voiceBilling: {
    key: url('/voice-billing/'),
    jurisdictions: {
      list: url('/voice-billing/jurisdictions/:action?/:id?/'),
    },
    SIPTrunks: {
      list: url('/voice-billing/sip-trunks/:action?/:id?/'),
    },
    phoneNumberRanges: {
      list: url('/voice-billing/phone-number-ranges/:action?/:id?/'),
    },
    baseRateCards: {
      list: url('/voice-billing/base-rate-cards/:action?/:id?/'),
    },
    customRateCards: {
      list: url('/voice-billing/custom-rate-cards/:action?/:id?/'),
    },
    rateBundles: {
      list: url('/voice-billing/rate-bundles/:action?/:id?/'),
    },
    clientReports: {
      list: url('/voice-billing/client-reports/:action?/:id?/'),
    },
    reachReports: {
      list: url('/voice-billing/reach-reports/:action?/:id?/'),
    },
    consolidatedReports: {
      list: url('/voice-billing/consolidated-reports/:action?/:id?/'),
    },
    discountAgreements: {
      list: url('/voice-billing/discount-agreements/:action?/:id?/'),
    },
    reportRuns: {
      list: url('/voice-billing/report-runs/'),
    },
    answerPoints: {
      list: url('/voice-billing/answer-points/:action?/:id?/'),
    },
  },
  reports: {
    key: url('/reports/'),
    invoices: url('/reports/invoices/'),
  },
  locationSearch: {
    key: url('/location-search/'),
    index: url('/location-search/'),
  },
  admin: {
    key: url('/admin/'),
    userAndGroups: {
      key: url('/admin/user-and-groups/'),
      users: {
        list: url('/admin/user-and-groups/users/:action?/:id?/'),
      },
      userGroups: {
        list: url('/admin/user-and-groups/user-groups/:action?/:id?/'),
      },
      permissions: {
        list: url('/admin/user-and-groups/permissions/:action?/:id?/'),
      },
      permissionsSets: {
        list: url('/admin/user-and-groups/permissions-sets/:action?/:id?/'),
      },
    },
    circuitsAndDevices: {
      key: url('/admin/circuit-and-devices/'),
      circuitTypes: {
        list: url('/admin/circuit-and-devices/circuit-types/:action?/:id?/'),
      },
      deviceManufacturers: {
        list: url(
          '/admin/circuit-and-devices/device-manufacturers/:action?/:id?/'
        ),
      },
      deviceTags: {
        list: url('/admin/circuit-and-devices/device-tags/:action?/:id?/'),
      },
      interfaceConfigurations: {
        list: url(
          '/admin/circuit-and-devices/interface-configurations/:action?/:id?/'
        ),
      },
      bandwidths: {
        list: url('/admin/circuit-and-devices/bandwidths/:action?/:id?/'),
      },
      interfaceTypes: {
        list: url('/admin/circuit-and-devices/interface_types/:action?/:id?/'),
      },
      deviceCategories: {
        list: url(
          '/admin/circuit-and-devices/device-categories/:action?/:id?/'
        ),
      },
    },
    vendorManagement: {
      key: url('/admin/vendor-management/'),
      carriers: {
        list: url('/admin/vendor-management/carriers/:action?/:id?/'),
      },
      maintenanceOrganizations: {
        list: url(
          '/admin/vendor-management/maintenance-organizations/:action?/:id?/'
        ),
      },
    },
    provisioning: {
      key: url('/admin/provisioning/'),
      customerStatuses: {
        list: url('/admin/provisioning/customer-statuses/:action?/:id?/'),
      },
    },
    serviceMessages: {
      key: url('/admin/service-messages/'),
      list: url('/admin/service-messages/:action?/:id?/'),
    },
    emailSettings: {
      key: url('/admin/email-settings/'),
      emailSettings: {
        list: url('/admin/email-settings/email-settings/:action?/:id?/'),
      },
      emails: {
        list: url('/admin/email-settings/emails/:action?/:id?/'),
      },
    },
    productManagement: {
      key: url('/admin/product-management/'),
      handoverDocumentTemplates: {
        list: url(
          '/admin/product-management/handover-document-templates/:action?/:id?/'
        ),
      },
      serviceCategories: {
        list: url(
          '/admin/product-management/service-categories/:action?/:id?/'
        ),
      },
      serviceTypes: {
        list: url('/admin/product-management/service-types/:action?/:id?/'),
      },
      workPlanTemplates: {
        list: url(
          '/admin/product-management/work-plan-templates/:action?/:id?/'
        ),
      },
    },
    connectwise: {
      key: url('/admin/connectwise/'),
      syncEvents: {
        list: url('/admin/connectwise/sync-events/:action?/:id?/'),
      },
    },
    voiceBilling: {
      key: url('/admin/voice-billing/'),
      callTypes: {
        list: url('/admin/voice-billing/call-types/:action?/:id?/'),
      },
      callSubtypes: {
        list: url('/admin/voice-billing/call-subtypes/:action?/:id?/'),
      },
      voiceCodecs: {
        list: url('/admin/voice-billing/voice-codecs/:action?/:id?/'),
      },
    },
    formBuilder: {
      key: url('/admin/form-builder/'),
      forms: {
        list: url('/admin/form-builder/forms/:action?/:id?/'),
      },
      templates: {
        list: url('/admin/form-builder/templates/:action?/:id?/'),
      },
    },
    operations: {
      key: url('/admin/operations/'),
      circuitRelocation: {
        index: url('/admin/operations/circuit-relocation/'),
      },
      tagManagement: {
        index: url('/admin/operations/tag-management/'),
      },
    },
  },
  userInvitations: {
    acceptInvitation: url('/i/:invitationKey/'),
  },
  customerUI: {
    dashboard: {
      index: url('/'),
    },
    companyProfile: {
      index: url('/company-profile/'),
    },
    serviceDelivery: {
      index: url('/service-delivery/'),
      detail: url('/service-delivery/:id/'),
    },
    services: {
      index: url('/services/'),
      detail: url('/services/:id/'),
    },
    mobileVoice: {
      index: url('/mobile-voice/'),
      detail: url('/mobile-voice/:id/'),
    },
    managedServices: {
      index: url('/managed-services/'),
      detail: url('/managed-services/:id/'),
      devices: {
        detail: url('/managed-services/:id/devices/:deviceId/'),
      },
    },
    changeManagement: {
      key: url('/change-management/'),
      changes: {
        index: url('/change-management/changes/'),
        detail: url('/change-management/changes/:id/'),
      },
      requests: {
        index: url('/change-management/requests/'),
        detail: url('/change-management/requests/:id/'),
      },
    },
    support: {
      index: url('/support/'),
      openTicket: url('/support/tickets/open/'),
      detail: url('/support/tickets/:id/'),
    },
    reports: {
      index: url('/reports/'),
      services: url('/reports/data-and-voice-services/'),
      supportTickets: url('/reports/support-requests/'),
      changeManagement: url('/reports/change-management/'),
      managedDevices: url('/reports/managed-devices/'),
      managedServicesDeviceAvailability: url(
        '/reports/managed-device-availability/'
      ),
      serviceAvailability: url('/reports/data-services-availability/'),
      alertHistory: url('/reports/alert-history/'),
    },
    forms: {
      detail: url('/forms/:id/'),
    },
    monitoring: {
      key: url('/monitoring/'),
      ddos: {
        index: url('/monitoring/ddos-protect/'),
        monitoring: {
          index: url('/monitoring/ddos-protect/monitoring/'),
        },
        protectedObjects: {
          index: url('/monitoring/ddos-protect/protected-objects/'),
        },
        mitigations: {
          index: url('/monitoring/ddos-protect/mitigations/'),
        },
      },
      myNetwork: {
        index: url('/monitoring/my-network/'),
      },
    },
    userInvitations: {
      acceptInvitation: url('/i/:invitationKey/'),
    },
  },
  publicUI: {
    forms: {
      detail: url('/forms/:id/'),
    },
    changeManagement: {
      requests: {
        detail: url('/change-requests/:id/'),
      },
    },
    userInvitations: {
      acceptInvitation: url('/i/:invitationKey/'),
    },
  },
}

export const DATA_URLS = {
  generic: {
    ws: {
      version: url('/ws/generic/version/'),
    },
  },
  internal: {
    auth: {
      validateADToken: url('/auth/validate_ad_token/'),
      loginAs: url('/auth/login_as/'),
      reLoginAsSelf: url('/auth/relogin_as_self/'),
      userInfo: url('/auth/user_info/'),
      changePassword: url('/auth/change_password/'),
      logActivity: url('/auth/log_activity/'),
      accessNotification: url('/auth/access_notification/'),
      switchCompany: url('/auth/switch_company/'),
    },
    bandwidths: {
      list: url('/bandwidths/'),
      details: url('/bandwidths/:pk/'),
      ws: {
        list: url('/ws/bandwidths/listen/'),
        details: url('/ws/bandwidths/:pk/listen/'),
      },
    },
    carriers: {
      list: url('/carriers/'),
      ws: {
        list: url('/ws/carriers/listen/'),
        details: url('/ws/carriers/:pk/listen/'),
      },
    },
    changeManagement: {
      changes: {
        list: url('/change_management/changes/'),
        ws: {
          list: url('/ws/change_management/changes/listen/'),
          details: url('/ws/change_management/changes/:pk/listen/'),
        },
        affectedClientCompanies: url(
          '/change_management/changes/:pk/affected_client_companies/'
        ),
        affectedPartnerCompanies: url(
          '/change_management/changes/:pk/affected_partner_companies/'
        ),
        sendChangeNotificationsToClients: url(
          '/change_management/changes/:pk/send_change_notifications_to_clients/'
        ),
        sendChangeNotificationsToPartners: url(
          '/change_management/changes/:pk/send_change_notifications_to_partners/'
        ),
        sendTestEmail: url('/change_management/changes/:pk/send_test_email/'),
      },
      requests: {
        list: url('/change_management/change_requests/'),
        details: url('/change_management/change_requests/:pk/'),
        internalPreview: url(
          '/change_management/change_requests/:pk/internal_preview/'
        ),
        sendApprovalEmails: url(
          '/change_management/change_requests/:pk/send_approval_emails/'
        ),
        resendApprovalEmails: url(
          '/change_management/change_requests/:pk/resend_approval_emails/'
        ),
        markAsCompleted: url(
          '/change_management/change_requests/:pk/mark_as_completed/'
        ),
      },
      requestApprovals: {
        list: url('/change_management/change_request_approvals/'),
        getByUniqueId: url(
          '/change_management/change_request_approvals/:unique_id/get_by_unique_id/'
        ),
        approve: url(
          '/change_management/change_request_approvals/:pk/approve/'
        ),
        reject: url('/change_management/change_request_approvals/:pk/reject/'),
      },
      changeRequestManualApprovals: {
        list: url('/change_management/change_request_manual_approvals/'),
        details: url('/change_management/change_request_manual_approvals/:pk/'),
      },
      requestEvents: {
        list: url('/change_management/change_request_events/'),
      },
      changeEvents: {
        list: url('/change_management/change_events/'),
        notificationsPreview: url(
          '/change_management/change_events/:pk/notifications_preview/'
        ),
        sendNotifications: url(
          '/change_management/change_events/:pk/send_notifications/'
        ),
        sendTestEmail: url(
          '/change_management/change_events/:pk/send_test_email/'
        ),
      },
      changeTypes: {
        list: url('/change_management/change_types/'),
      },
      changeStatuses: {
        list: url('/change_management/change_statuses/'),
      },
      changeRiskTypes: {
        list: url('/change_management/change_risk_types/'),
      },
      changeUrgencyTypes: {
        list: url('/change_management/change_urgency_types/'),
      },
      changeImpactTypes: {
        list: url('/change_management/change_impact_types/'),
      },
      changeCategoryTypes: {
        list: url('/change_management/change_category_types/'),
      },
      companyTickets: {
        list: url('/change_management/connectwise_tickets/'),
      },
    },
    circuits: {
      list: url('/circuits/'),
      short: url('/circuits/short/'),
      expiring: url('/circuits/expiring/'),
      attached: url('/circuits/attached/'),
      unattached: url('/circuits/unattached/'),
      summary: url('/circuits/summary/'),
      relocate: url('/circuits/relocate/'),
      details: url('/circuits/:pk/'),
      replicateInNetbox: url('/circuits/:pk/replicate_in_netbox/'),
      ws: {
        list: url('/ws/circuits/listen/'),
        details: url('/ws/circuits/:pk/listen/'),
      },
      types: {
        list: url('/circuits/types/'),
        ws: {
          list: url('/ws/circuits/types/listen/'),
          details: url('/ws/circuits/types/:pk/listen/'),
        },
      },
      subtypes: {
        list: url('/circuits/subtypes/'),
        ws: {
          list: url('/ws/circuits/subtypes/listen/'),
          details: url('/ws/circuits/subtypes/:pk/listen/'),
        },
      },
      attachments: {
        list: url('/circuits/attachments/'),
        details: url('/circuits/attachments/:pk/'),
      },
    },
    companies: {
      list: url('/companies/'),
      ws: {
        list: url('/ws/companies/listen/'),
        details: url('/ws/companies/:pk/listen/'),
      },
      externalCompanies: url('/companies/get_external_companies/'),
      details: url('/companies/:pk/'),
      companyContacts: {
        list: url('/companies/contacts/'),
        details: url('/companies/contacts/:pk/'),
        ws: {
          list: url('/ws/companies/contacts/listen/'),
          details: url('/ws/companies/contacts/:pk/listen/'),
        },
      },
      companyProfile: {
        details: url('/companies/profile/'),
        companyContacts: {
          list: url('/companies/profile/contacts/'),
          details: url('/companies/profile/contacts/:pk/'),
        },
        companyCustomers: {
          list: url('/companies/profile/customers/'),
        },
      },
    },
    contacts: {
      list: url('/contacts/'),
      ws: {
        list: url('/ws/contacts/listen/'),
        details: url('/ws/contacts/:pk/listen/'),
      },
      details: url('/contacts/:pk/'),
      categoryTypes: {
        list: url('/contacts/category_types/'),
      },
    },
    customerHandover: {
      handoverDocumentTemplates: {
        list: url('/customer_handover/handover_document_templates/'),
        preview: url('/customer_handover/handover_document_templates/preview/'),
        ws: {
          list: url(
            '/ws/customer_handover/handover_document_templates/listen/'
          ),
          details: url(
            '/ws/customer_handover/handover_document_templates/:pk/listen/'
          ),
        },
      },
    },
    devices: {
      list: url('/devices/'),
      expiring: url('/devices/expiring/'),
      withMaintenanceContracts: url('/devices/with_maintenance_contracts/'),
      validForManagedServices: url('/devices/valid_for_managed_services/'),
      details: url('/devices/:pk/'),
      updateTags: url('/devices/update_tags/'),
      ws: {
        list: url('/ws/devices/listen/'),
        details: url('/ws/devices/:pk/listen/'),
        customerAlerts: url('/ws/devices/customer/alert_events/listen/'),
      },
      tags: {
        list: url('/devices/tags/'),
      },
      attachments: {
        list: url('/devices/attachments/'),
        ws: {
          list: url('/ws/devices/attachments/listen/'),
          details: url('/ws/devices/attachments/:pk/listen/'),
        },
      },
      manufacturers: {
        list: url('/devices/manufacturers/'),
        ws: {
          list: url('/ws/devices/manufacturers/listen/'),
          details: url('/ws/devices/manufacturers/:pk/listen/'),
        },
      },
      aggregationPorts: {
        list: url('/devices/aggregation_ports/'),
        ws: {
          list: url('/ws/devices/aggregation_ports/listen/'),
          details: url('/ws/devices/aggregation_ports/:pk/listen/'),
        },
      },
      models: {
        list: url('/devices/models/'),
        ws: {
          list: url('/ws/devices/models/listen/'),
          details: url('/ws/devices/models/:pk/listen/'),
        },
      },
      interfaceConfigurations: {
        list: url('/devices/interface_configurations/'),
        ws: {
          list: url('/ws/devices/interface_configurations/listen/'),
          details: url('/ws/devices/interface_configurations/:pk/listen/'),
        },
      },
      monitoringObjects: {
        list: url('/devices/monitoring_objects/'),
        throughput: url('/devices/monitoring_objects/:pk/throughput/'),
        indicatorData: url('/devices/monitoring_objects/:pk/indicator_data/'),
        ws: {
          list: url('/ws/devices/monitoring_objects/listen/'),
          details: url('/ws/devices/monitoring_objects/:pk/listen/'),
        },
      },
      categories: {
        list: url('/devices/categories/'),
        ws: {
          list: url('/ws/devices/categories/listen/'),
          details: url('/ws/devices/categories/:pk/listen/'),
        },
      },
      configAbbreviations: {
        list: url('/devices/config_abbreviations/'),
      },
    },
    interfaceTypes: {
      list: url('/interface_types/'),
      ws: {
        list: url('/ws/interface_types/listen/'),
        details: url('/ws/interface_types/:pk/listen/'),
      },
    },
    locations: {
      list: url('/locations/'),
      details: url('/locations/:pk/'),
      ws: {
        list: url('/ws/locations/listen/'),
        details: url('/ws/locations/:pk/listen/'),
      },
      superloop: {
        createLocationSearch: url(
          '/locations/superloop/create_location_search/'
        ),
        getLocationSearchResults: url(
          '/locations/superloop/get_location_search_results/'
        ),
        createQualificationRequest: url(
          '/locations/superloop/create_qualification_request/'
        ),
        getQualificationResults: url(
          '/locations/superloop/get_qualification_results/'
        ),
      },
      optus: {
        siteQualification: url('/locations/optus/site_qualification/'),
        getSiteQualificationResult: url(
          '/locations/optus/get_site_qualification_result/'
        ),
      },
      swoop: {
        getCoverage: url('/locations/swoop/get_coverage/'),
      },
      telstra: {
        createProductOfferingsRequest: url(
          '/locations/telstra/product_offerings/'
        ),
        getProductOfferings: url('/locations/telstra/get_product_offerings/'),
        addressSearch: url('/locations/telstra/address/search/'),
      },
      aapt: {
        qualifyProduct: url('/locations/aapt/qualify_product/'),
        getProductQualification: url(
          '/locations/aapt/get_product_qualification/'
        ),
      },
      addressFinder: {
        addressAutocomplete: url(
          '/locations/addressfinder/address_autocomplete/'
        ),
        addressMetadata: url(
          '/locations/addressfinder/address_metadata/:af_address_id/'
        ),
      },
      geoscape: {
        geocodeAddress: url('/locations/geoscape/geocode_address/'),
      },
    },
    managedServices: {
      list: url('/managed_services/'),
      details: url('/managed_services/:pk/'),
      ws: {
        list: url('/ws/managed_services/listen/'),
        details: url('/ws/managed_services/:pk/listen/'),
      },
    },
    maintenance: {
      organizations: {
        list: url('/maintenance/organizations/'),
        ws: {
          list: url('/ws/maintenance/organizations/listen/'),
          details: url('/ws/maintenance/organizations/:pk/listen/'),
        },
      },
      contracts: {
        list: url('/maintenance/contracts/'),
        addDevices: url('/maintenance/contracts/:pk/add_devices/'),
        removeDeviceFromContract: url(
          '/maintenance/contracts/remove_maintained_device_from_contract/:pk/'
        ),
        ws: {
          list: url('/ws/maintenance/contracts/listen/'),
          details: url('/ws/maintenance/contracts/:pk/listen/'),
        },
      },
    },
    mentions: {
      unread: url('/mentions/unread/'),
      history: url('/mentions/history/'),
      markAllAsRead: url('/mentions/mark_all_as_read/'),
      markAsRead: url('/mentions/:pk/mark_as_read/'),
      ws: {
        unread: url('/ws/mentions/unread/listen/'),
      },
    },
    notifications: {
      types: {
        list: url('/notifications/notification_types/'),
        details: url('/ws/notifications/notification_types/:pk/listen/'),
      },
      emails: {
        list: url('/notifications/emails/'),
        ws: {
          list: url('/ws/notifications/emails/listen/'),
          details: url('/ws/notifications/emails/:pk/listen/'),
        },
      },
      emailEvents: {
        list: url('/notifications/email_events/'),
      },
      emailSettings: {
        list: url('/notifications/email_settings/'),
        ws: {
          list: url('/ws/notifications/email_settings/listen/'),
          details: url('/ws/notifications/email_settings/:pk/listen/'),
        },
      },
      events: {
        types: {
          list: url('/notifications/event_types/'),
        },
      },
    },
    orders: {
      list: url('/orders/'),
      grouped: url('/orders/grouped/'),
      ws: {
        list: url('/ws/internal/orders/listen/'),
        details: url('/ws/internal/orders/:pk/listen/'),
      },
      summary: {
        list: url('/orders/summary/'),
        details: url('/orders/summary/:pk/'),
        submitEnquiryRequest: url(
          '/orders/summary/:pk/submit_enquiry_request/'
        ),
        ws: {
          details: url('/ws/orders/summary/:pk/listen/'),
        },
      },
      provisioningStatistic: url('/orders/provisioning_statistic/'),

      cancel: url('/orders/:pk/cancel/'),
      generateHandoverPackDocument: url(
        '/orders/:pk/generate_handover_pack_document/'
      ),
      sendProvisioningUpdate: url('/orders/:pk/send_provisioning_update/'),
      syncWithConnectWise: url('/orders/:pk/sync_with_connectwise/'),
      detachFromConnectWise: url('/orders/:pk/detach_from_connectwise/'),
      generateQuestionnaireForm: url(
        '/orders/:pk/generate_questionnaire_form/'
      ),
      details: url('/orders/:pk/'),
      update: url('/orders/:pk/:action/'),
      progress: url('/orders/:pk/progress/'),
      workPlan: url('/orders/:pk/work_plan/'),
      follow: url('/orders/:pk/follow/'),
      unfollow: url('/orders/:pk/unfollow/'),

      workPlans: {
        phases: {
          update: url('/orders/work_plan/:work_plan_id/phases/:phase_id/'),
        },
        tickets: {
          update: url(
            '/orders/work_plan/:work_plan_id/tickets/update/:ticket_id/'
          ),
          details: url('/orders/work_plan/:work_plan_id/tickets/:ticket_id/'),
          timeEntries: {
            list: url(
              '/orders/work_plan/:work_plan_id/tickets/:ticket_id/time_entries/'
            ),
          },
        },
        grouped: {
          list: url('/orders/work_plan/grouped/'),
        },
        phaseStatuses: {
          list: url('/orders/work_plan/phase_statuses/'),
        },
        taskStatuses: {
          list: url('/orders/work_plan/task_statuses/'),
        },
        taskPriorities: {
          list: url('/orders/work_plan/task_priorities/'),
        },
      },
      workOrders: {
        list: url('/orders/work_orders/'),
      },
      attachments: {
        list: url('/orders/attachments/'),
      },
      attachmentTypes: {
        list: url('/orders/attachment_types/'),
      },
      changeTypes: {
        list: url('/orders/change_types/'),
      },
      types: {
        list: url('/orders/types/'),
      },
      comments: {
        list: url('/orders/comments/'),
        createAcknowledgements: url(
          '/orders/comments/create_acknowledgements/'
        ),
      },
    },
    permissions: {
      list: url('/permissions/'),
      groups: {
        list: url('/permissions/groups/'),
        ws: {
          list: url('/ws/permissions/groups/listen/'),
          details: url('/ws/permissions/groups/:pk/listen/'),
        },
      },
      sets: {
        list: url('/permissions/sets/'),
        ws: {
          list: url('/ws/permissions/permission_sets/listen/'),
          details: url('/ws/permissions/permission_sets/:pk/listen/'),
        },
      },
      userGroupRelationships: {
        list: url('/permissions/user_group_relationships/'),
      },
    },
    plugins: {
      connectWise: {
        syncEvents: {
          list: url('/plugins/connectwise/sync_events/'),
          ws: {
            list: url('/ws/plugins/connectwise/sync_events/listen/'),
            details: url('/ws/plugins/connectwise/sync_events/:pk/listen/'),
          },
        },
        events: {
          types: {
            list: url('/plugins/connectwise/event_types/'),
          },
        },
      },
      salesforce: {
        opportunities: {
          list: url('/plugins/salesforce/opportunities/'),
        },
      },
      telcoinabox: {
        address: {
          validate: url('/plugins/telcoinabox/address/validate/'),
        },
        simCards: {
          checkAvailability: url(
            '/plugins/telcoinabox/sim-cards/check-availability/'
          ),
          otp: {
            send: url('/plugins/telcoinabox/sim-cards/otp/send/'),
            verify: url('/plugins/telcoinabox/sim-cards/otp/verify/'),
          },
          plans: url('/plugins/telcoinabox/sim-cards/plans/'),
        },
        customers: {
          list: url('/plugins/telcoinabox/customers/'),
        },
      },
      telstra: {
        address: {
          search: url('/plugins/telstra/address/search/'),
        },
      },
      netbox: {
        sites: {
          list: url('/plugins/netbox/sites/'),
        },
      },
      wanguard: {
        ipGroups: {
          list: url('/plugins/wanguard/ip_groups/'),
        },
      },
    },
    serviceMessages: {
      messages: {
        list: url('/service-messages/messages/'),
        ws: {
          list: url('/ws/service-messages/messages/listen/'),
          details: url('/ws/service-messages/messages/:pk/listen/'),
        },
        details: url('/service-messages/messages/:pk/'),
      },
      levels: {
        list: url('/service-messages/levels/'),
      },
      categories: {
        list: url('/service-messages/categories/'),
      },
    },
    provisioning: {
      customerStatuses: {
        list: url('/provisioning/customer_statuses/'),
        ws: {
          list: url('/ws/provisioning/customer_statuses/listen/'),
          details: url('/ws/provisioning/customer_statuses/:pk/listen/'),
        },
      },
      statusTypes: {
        list: url('/provisioning/status_types/'),
      },
      codenameTypes: {
        list: url('/provisioning/codename_types/'),
      },
      workPlanTemplates: {
        list: url('/provisioning/work_plan_templates/'),
        details: url('/provisioning/work_plan_templates/:pk/'),
        ws: {
          list: url('/ws/provisioning/work_plan_templates/listen/'),
          details: url('/ws/provisioning/work_plan_templates/:pk/listen/'),
        },
      },
    },
    services: {
      list: url('/services/'),
      details: url('/services/:pk/'),
      topology: url('/services/:pk/topology/'),
      attachCellularSIMCard: url('/services/:pk/attach_cellular_sim_card/'),
      detachCellularSIMCard: url('/services/:pk/detach_cellular_sim_card/'),
      attachSIPTrunk: url('/services/:pk/attach_sip_trunk/'),
      detachSIPTrunk: url('/services/:pk/detach_sip_trunk/'),
      attachPhoneNumberRange: url('/services/:pk/attach_phone_number_range/'),
      detachPhoneNumberRange: url('/services/:pk/detach_phone_number_range/'),
      attachAnswerPoint: url('/services/:pk/attach_answer_point/'),
      detachAnswerPoint: url('/services/:pk/detach_answer_point/'),
      attachCircuit: url('/services/:pk/attach_circuit/'),
      detachCircuit: url('/services/:pk/detach_circuit/'),
      ws: {
        list: url('/ws/services/listen/'),
        details: url('/ws/services/:pk/listen/'),
      },
      categories: {
        list: url('/services/categories/'),
        ws: {
          list: url('/ws/services/categories/listen/'),
          details: url('/ws/services/categories/:pk/listen/'),
        },
      },
      types: {
        list: url('/services/types/'),
        details: url('/services/types/:pk/'),
        ws: {
          list: url('/ws/services/types/listen/'),
          details: url('/ws/services/types/:pk/listen/'),
        },
      },
    },
    SIMCards: {
      list: url('/sim_cards/'),
      attached: url('/sim_carts/attached/'),
      unattached: url('/sim_cards/unattached/'),
      setTag: url('/sim_cards/:pk/set_tag/'),
      setStatus: url('/sim_cards/:pk/set_status/'),
      connectionHistory: url('/sim_cards/:pk/connection_history/'),
      ws: {
        list: url('/ws/sim_cards/listen/'),
        details: url('/ws/sim_cards/:pk/listen/'),
      },
      changeLogs: {
        list: url('/sim_cards/change_logs/'),
      },
    },
    telcoInabox: {
      list: url('/sim_cards/telcoinabox/sim_cards/'),
      fleets: url('/sim_cards/telcoinabox/fleets/'),
      attached: url('/sim_cards/telcoinabox/sim_cards/attached/'),
      setTag: url('/sim_cards/telcoinabox/sim_cards/:pk/set_tag/'),
    },
    support: {
      issueTypes: {
        list: url('/support/issue_types/'),
      },
      incidentCategories: {
        list: url('/support/incident_categories/'),
      },
      incidentAffects: {
        list: url('/support/incident_affects/'),
      },
      incidentSeverity: {
        list: url('/support/incident_severity/'),
      },
      statuses: {
        list: url('/support/statuses/'),
      },
      ticketAttachments: {
        list: url('/support/ticket_attachments/'),
      },
    },
    users: {
      list: url('/users/'),
      manage: url('/users/manage/'),
      info: url('/users/info/:username/'),
      detail: url('/users/manage/:pk/'),
      loginHistory: url('/users/manage/:pk/login_history/'),
      externalMembers: url('/users/manage/external_members/'),
      ws: {
        list: url('/ws/users/listen/'),
        details: url('/ws/users/:pk/listen/'),
      },
    },
    voiceBilling: {
      bulkDownloadClientReports: url(
        '/voice_billing/client_reports/bulk_download/'
      ),
      downloadClientReport: url('/voice_billing/client_reports/:pk/download/'),
      bulkDownloadConsolidatedReports: url(
        '/voice_billing/consolidated_reports/bulk_download/'
      ),
      downloadConsolidatedReport: url(
        '/voice_billing/consolidated_reports/:pk/download/'
      ),
      bulkDownloadReachReports: url(
        '/voice_billing/reach_reports/bulk_download/'
      ),
      downloadReachReport: url('/voice_billing/reach_reports/:pk/download/'),
      downloadExceptionsReport: url(
        '/voice_billing/download_exception_report/:pk/'
      ),
      reportRuns: {
        list: url('/voice_billing/report_runs/'),
      },
      baseRateCards: {
        list: url('/voice_billing/base_rate_cards/'),
        ws: {
          list: url('/ws/voice_billing/base_rate_cards/listen/'),
          details: url('/ws/voice_billing/base_rate_cards/:pk/listen/'),
        },
        export: url('/voice_billing/base_rate_cards/export/'),
        importCosts: url('/voice_billing/base_rate_cards/import_costs/'),
      },
      baseRateCardCosts: {
        list: url('/voice_billing/base_rate_card_costs/'),
      },
      customRateCards: {
        list: url('/voice_billing/custom_rate_cards/'),
        ws: {
          list: url('/ws/voice_billing/custom_rate_cards/listen/'),
          details: url('/ws/voice_billing/custom_rate_cards/:pk/listen/'),
        },
      },
      customRateCardCosts: {
        list: url('/voice_billing/custom_rate_card_costs/'),
        ws: {
          list: url('/ws/voice_billing/custom_rate_card_costs/listen/'),
          details: url('/ws/voice_billing/custom_rate_card_costs/:pk/listen/'),
        },
      },
      jurisdictions: {
        list: url('/voice_billing/jurisdictions/'),
        ws: {
          list: url('/ws/voice_billing/jurisdictions/listen/'),
          details: url('/ws/voice_billing/jurisdictions/:pk/listen/'),
        },
      },
      callTypes: {
        list: url('/voice_billing/call_types/'),
        ws: {
          list: url('/ws/voice_billing/call_types/listen/'),
          details: url('/ws/voice_billing/call_types/:pk/listen/'),
        },
      },
      callSubtypes: {
        list: url('/voice_billing/call_subtypes/'),
        ws: {
          list: url('/ws/voice_billing/call_subtypes/listen/'),
          details: url('/ws/voice_billing/call_subtypes/:pk/listen/'),
        },
      },
      phoneNumberRanges: {
        list: url('/voice_billing/phone_number_ranges/'),
        unattached: url('/voice_billing/phone_number_ranges/unattached/'),
        ws: {
          list: url('/ws/voice_billing/phone_number_ranges/listen/'),
          details: url('/ws/voice_billing/phone_number_ranges/:pk/listen/'),
        },
      },
      SIPTrunks: {
        list: url('/voice_billing/sip_trunks/'),
        unattached: url('/voice_billing/sip_trunks/unattached/'),
        ws: {
          list: url('/ws/voice_billing/sip_trunks/listen/'),
          details: url('/ws/voice_billing/sip_trunks/:pk/listen/'),
        },
      },
      voiceCodecs: {
        list: url('/voice_billing/voice_codecs/'),
        ws: {
          list: url('/ws/voice_billing/voice_codecs/listen/'),
          details: url('/ws/voice_billing/voice_codecs/:pk/listen/'),
        },
      },
      clientReports: {
        list: url('/voice_billing/client_reports/'),
      },
      reachReports: {
        list: url('/voice_billing/reach_reports/'),
      },
      consolidatedReports: {
        list: url('/voice_billing/consolidated_reports/'),
      },
      discountAgreements: {
        list: url('/voice_billing/discount_agreements/'),
        ws: {
          list: url('/ws/voice_billing/discount_agreements/listen/'),
          details: url('/ws/voice_billing/discount_agreements/:pk/listen/'),
        },
      },
      bundledRateCards: {
        list: url('/voice_billing/bundled_rate_cards/'),
        ws: {
          list: url('/ws/voice_billing/bundled_rate_cards/listen/'),
          details: url('/ws/voice_billing/bundled_rate_cards/:pk/listen/'),
        },
      },
      rateBundles: {
        list: url('/voice_billing/rate_bundles/'),
        ws: {
          list: url('/ws/voice_billing/rate_bundles/listen/'),
          details: url('/ws/voice_billing/rate_bundles/:pk/listen/'),
        },
      },
      answerPoints: {
        list: url('/voice_billing/answer_points/'),
        unattached: url('/voice_billing/answer_points/unattached/'),
      },
    },
    releaseNotes: url('/release_notes/'),
    constructedForms: {
      list: url('/constructed_forms/forms/'),
      populateLinkedObjectData: url(
        '/constructed_forms/forms/:pk/populate_linked_object_data/'
      ),
      ws: {
        list: url('/ws/constructed_forms/forms/listen/'),
        details: url('/ws/constructed_forms/forms/:pk/listen/'),
      },
      templates: {
        list: url('/constructed_forms/templates/'),
        duplicate: url('/constructed_forms/templates/:pk/duplicate/'),
        ws: {
          list: url('/ws/constructed_forms/templates/listen/'),
          details: url('/ws/constructed_forms/templates/:pk/listen/'),
        },
        models: {
          list: url('/constructed_forms/templates/models/'),
        },
        modelFields: {
          list: url('/constructed_forms/templates/model_fields/'),
        },
      },
      templateFiles: {
        list: url('/constructed_forms/template_files/'),
      },
      templateTypes: {
        list: url('/constructed_forms/template_types/'),
      },
      public: {
        details: url('/constructed_forms/public/:pk/'),
        submit: url('/constructed_forms/public/:pk/submit/'),
      },
    },
    inbox: {
      inboundEmails: {
        list: url('/inbox/inbound_emails/'),
        details: url('/inbox/inbound_emails/:pk/'),
      },
    },
    reports: {
      SIMMasterPoolUsage: url('/reports/sim_master_pool_usage/'),
      invoices: url('/reports/invoices/'),
    },
  },
  external: {
    orders: {
      list: url('/orders/'),
      details: url('/orders/:pk/'),
      submitEnquiryRequest: url('/orders/:pk/submit_enquiry_request/'),
      comments: {
        list: url('/orders/comments/'),
        createAcknowledgements: url(
          '/orders/comments/create_acknowledgements/'
        ),
      },
      ws: {
        details: url('/ws/external/orders/:pk/listen/'),
      },
    },
    devices: {
      list: url('/devices/'),
      pingOnly: {
        list: url('/devices/ping_only/'),
      },
      monitoringObjects: {
        list: url('/devices/monitoring_objects/'),
        throughput: url('/devices/monitoring_objects/:pk/throughput/'),
        indicatorData: url('/devices/monitoring_objects/:pk/indicator_data/'),
      },
      openAlerts: url('/devices/alerts/'),
      config: url('/devices/:id/config/'),
      configContents: url('/devices/:id/config_contents/'),
    },
    managedServices: {
      list: url('/managed_services/'),
      details: url('/managed_services/:pk/'),
      hasManagedServices: url('/managed_services/has_managed_services/'),
    },
    services: {
      list: url('/services/'),
      network: {
        list: url('/services/network/'),
      },
      generateHandoverPackDocument: url(
        '/services/:pk/generate_handover_pack_document/'
      ),
      setTag: url('/services/:pk/set_tag/'),
    },
    reports: {
      serviceSnapshot: url('/reports/service_snapshot/'),
      services: url('/reports/services/'),
      supportTickets: url('/reports/support_tickets/'),
      changeManagement: url('/reports/change_management/'),
      managedDevices: url('/reports/managed_devices/'),
      managedServicesDeviceAvailability: url(
        '/reports/managed_services_device_availability/'
      ),
      serviceAvailability: url('/reports/service_availability/'),
      alertsHistory: url('/reports/alert_history/'),
    },
    support: {
      tickets: {
        list: url('/support/tickets/'),
        externalData: {
          list: url('/support/tickets/:pk/external_data/'),
        },
        externalNotesAndTimeEntries: {
          list: url('/support/tickets/:pk/external_notes_and_time_entries/'),
          create: url('/support/tickets/:pk/add_external_note/'),
        },
      },
      issueTypes: {
        list: url('/support/issue_types/'),
      },
      incidentCategories: {
        list: url('/support/incident_categories/'),
      },
      incidentAffects: {
        list: url('/support/incident_affects/'),
      },
      incidentSeverity: {
        list: url('/support/incident_severity/'),
      },
      statuses: {
        list: url('/support/statuses/'),
      },
      ticketAttachments: {
        list: url('/support/ticket_attachments/'),
      },
    },
    circuits: {
      list: url('/circuits/'),
      details: url('/circuits/:pk/'),
    },
    changeManagement: {
      changes: {
        list: url('/change_management/changes/'),
      },
      changeEvents: {
        list: url('/change_management/change_events/'),
      },
      requests: {
        list: url('/change_management/change_requests/'),
        detail: url('/change_management/change_requests/:pk/'),
      },
      requestApprovals: {
        list: url('/change_management/change_request_approvals/'),
        detail: url('/change_management/change_request_approvals/:unique_id/'),
        verifyUser: url(
          '/change_management/change_request_approvals/:unique_id/verify_user/'
        ),
        approve: url(
          '/change_management/change_request_approvals/:unique_id/approve/'
        ),
        reject: url(
          '/change_management/change_request_approvals/:unique_id/reject/'
        ),
      },
    },
    SIMCards: {
      setTag: url('/sim_cards/:pk/set_tag/'),
      telcoInabox: {
        sim_cards: {
          list: url('/sim_cards/telcoinabox/sim_cards/'),
          details: url('/sim_cards/telcoinabox/sim_cards/:pk/'),
          balance: url('/sim_cards/telcoinabox/sim_cards/:pk/balance/'),
          setTag: url('/sim_cards/telcoinabox/sim_cards/:pk/set_tag/'),
        },
      },
    },
    companies: {
      companyProfile: {
        details: url('/companies/profile/'),
        companyContacts: {
          list: url('/companies/profile/contacts/'),
          details: url('/companies/profile/contacts/:pk/'),
        },
        companyCustomers: {
          list: url('/companies/profile/customers/'),
        },
        applications: {
          list: url('/companies/applications/'),
        },
        userManagement: {
          list: url('/companies/users/'),
          details: url('/companies/users/:pk/'),
        },
        invitations: {
          list: url('/companies/user_invitations/'),
          resend: url('/companies/user_invitations/:pk/resend/'),
          getByKey: url(
            '/companies/user_invitations/:invitationKey/get_by_key/'
          ),
          accept: url('/companies/user_invitations/:invitationKey/accept/'),
        },
      },
    },
    contacts: {
      list: url('/contacts/'),
      ws: {
        list: url('/ws/contacts/listen/'),
        details: url('/ws/contacts/:pk/listen/'),
      },
      details: url('/contacts/:pk/'),
      categoryTypes: {
        list: url('/contacts/category_types/'),
      },
    },
    plugins: {
      wanguard: {
        requestAccess: url('/plugins/wanguard/request-access/'),
        anomalies: {
          list: url('/plugins/wanguard/anomalies/'),
        },
        flowRecords: {
          geoData: url('/plugins/wanguard/flow_records/geo_data/'),
          bwUtilisation: url(
            '/plugins/wanguard/flow_records/bandwidth_utilisation/'
          ),
        },
        prefixes: {
          list: url('/plugins/wanguard/prefixes/'),
        },
      },
    },
    releaseNotes: url('/release_notes/'),
    permissions: {
      groups: {
        list: url('/permissions/groups/'),
      },
    },
    serviceMessages: {
      latest: url('/service-messages/latest/'),
    },
  },
}
