import React from 'react'

import { NavLink } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Menu } from 'antd'

import Icon from 'components/Icon'
import { URLS } from 'urls'

const MenuItems = (props) => {
  const {
    defaultOpenKeys,
    defaultSelectedKeys,
    selectedKeys,
    onOpenChange,
    optionalMenuProps,
    onMenuItemClick,
    enableSupportSection,
    enableManagedServicesSection,
  } = props

  return (
    <Menu
      mode="inline"
      theme="light"
      defaultOpenKeys={defaultOpenKeys}
      defaultSelectedKeys={defaultSelectedKeys}
      selectedKeys={selectedKeys}
      onOpenChange={onOpenChange}
      onClick={onMenuItemClick}
      style={{ height: '100%', borderRight: 0 }}
      {...optionalMenuProps}
    >
      <Menu.Item key={URLS.customerUI.dashboard.index.buildPath()}>
        <NavLink to={URLS.customerUI.dashboard.index.buildPath()}>
          <span>
            <Icon icon="faTachometerAlt" /> <span>Dashboard</span>
          </span>
        </NavLink>
      </Menu.Item>
      <Menu.Item key={URLS.customerUI.serviceDelivery.index.buildPath()}>
        <NavLink to={URLS.customerUI.serviceDelivery.index.buildPath()}>
          <span>
            <Icon icon="faCalendar" /> <span>Service Delivery</span>
          </span>
        </NavLink>
      </Menu.Item>
      <Menu.Item key={URLS.customerUI.services.index.buildPath()}>
        <NavLink to={URLS.customerUI.services.index.buildPath()}>
          <span>
            <Icon icon="faNetworkWired" /> <span>Services</span>
          </span>
        </NavLink>
      </Menu.Item>
      <Menu.Item key={URLS.customerUI.mobileVoice.index.buildPath()}>
        <NavLink to={URLS.customerUI.mobileVoice.index.buildPath()}>
          <Icon pro icon={'faMobile'} /> <span>NetSG Mobile</span>
        </NavLink>
      </Menu.Item>
      {enableManagedServicesSection && (
        <Menu.Item key={URLS.customerUI.managedServices.index.buildPath()}>
          <NavLink to={URLS.customerUI.managedServices.index.buildPath()}>
            <span>
              <Icon icon="faTasksAlt" pro /> <span>Managed Services</span>
            </span>
          </NavLink>
        </Menu.Item>
      )}
      <Menu.SubMenu
        key={URLS.customerUI.changeManagement.key.buildPath()}
        title={
          <span>
            <Icon icon="faListAlt" />
            <span>Change Management</span>
          </span>
        }
      >
        <Menu.Item
          key={URLS.customerUI.changeManagement.changes.index.buildPath()}
        >
          <NavLink
            to={URLS.customerUI.changeManagement.changes.index.buildPath()}
          >
            <span>
              <Icon icon="faTasks" /> <span>Notifications</span>
            </span>
          </NavLink>
        </Menu.Item>
        <Menu.Item
          key={URLS.customerUI.changeManagement.requests.index.buildPath()}
        >
          <NavLink
            to={URLS.customerUI.changeManagement.requests.index.buildPath()}
          >
            <span>
              <Icon icon="faQuestionSquare" pro /> <span>Requests</span>
            </span>
          </NavLink>
        </Menu.Item>
      </Menu.SubMenu>
      {(enableSupportSection === true ||
        enableSupportSection === undefined) && (
        <Menu.Item key={URLS.customerUI.support.index.buildPath()}>
          <NavLink to={URLS.customerUI.support.index.buildPath()}>
            <span>
              <Icon icon="faHandsHelping" /> <span>Support</span>
            </span>
          </NavLink>
        </Menu.Item>
      )}
      <Menu.Item key={URLS.customerUI.reports.index.buildPath()}>
        <NavLink to={URLS.customerUI.reports.index.buildPath()}>
          <span>
            <Icon icon="faFileExport" /> <span>Reports</span>
          </span>
        </NavLink>
      </Menu.Item>
      <Menu.SubMenu
        key={URLS.customerUI.monitoring.key.buildPath()}
        title={
          <span>
            <Icon icon="faShieldAlt" />
            <span>Monitoring</span>
          </span>
        }
      >
        <Menu.Item key={URLS.customerUI.monitoring.myNetwork.index.buildPath()}>
          <NavLink to={URLS.customerUI.monitoring.myNetwork.index.buildPath()}>
            <span>
              <Icon icon="faChartNetwork" pro /> <span>My Network</span>
            </span>
          </NavLink>
        </Menu.Item>
        {/*}
        <Menu.Item key={URLS.customerUI.monitoring.ddos.index.buildPath()}>
          <NavLink to={URLS.customerUI.monitoring.ddos.index.buildPath()}>
            <span>
              <Icon icon="faShieldAlt" pro /> <span>DDoS Protect</span>
              <BetaTag />
            </span>
          </NavLink>
        </Menu.Item>*/}
      </Menu.SubMenu>
    </Menu>
  )
}

MenuItems.propTypes = {
  defaultOpenKeys: PropTypes.array.isRequired,
  defaultSelectedKeys: PropTypes.array.isRequired,
  selectedKeys: PropTypes.array.isRequired,
  onOpenChange: PropTypes.func.isRequired,
  optionalMenuProps: PropTypes.object.isRequired,
  onMenuItemClick: PropTypes.func.isRequired,
  enableSupportSection: PropTypes.bool,
  enableManagedServicesSection: PropTypes.bool.isRequired,
}

export default MenuItems
