import React from 'react'
import Loadable from 'react-loadable'
import { Switch, Route, Redirect } from 'react-router-dom'

import { LoadingComponent } from 'components/LoadingComponent'
import { PrivateRoute } from 'components/PrivateRoute'
import LoginPage from 'containers/Auth/LoginPage'
import { URLS } from 'urls'

/**
 * Auth related.
 */
const AuthCallback = Loadable({
  loader: () => import('./containers/Auth/AuthCallback'),
  loading: LoadingComponent,
})
const LogoutPage = Loadable({
  loader: () => import('./containers/Auth/LogoutPage'),
  loading: LoadingComponent,
})
const AccountNotSetYetPage = Loadable({
  loader: () => import('./containers/Auth/AccountNotSetYetPage'),
  loading: LoadingComponent,
})

/**
 * Exceptions
 */

const PageNotFound = Loadable({
  loader: () => import('./containers/Exceptions/PageNotFound'),
  loading: LoadingComponent,
})

/***************************
 * SUPERADMIN APPLICATION
 ***************************/

const SuperAdminApplication = {
  /**
   * Service management.
   */
  Locations: Loadable({
    loader: () => import('./containers/StaffUI/Locations'),
    loading: LoadingComponent,
  }),
  Circuits: Loadable({
    loader: () => import('./containers/StaffUI/Circuits'),
    loading: LoadingComponent,
  }),
  Devices: Loadable({
    loader: () => import('./containers/StaffUI/Devices'),
    loading: LoadingComponent,
  }),
  MaintenanceContracts: Loadable({
    loader: () => import('./containers/StaffUI/MaintenanceContracts'),
    loading: LoadingComponent,
  }),
  Services: Loadable({
    loader: () => import('./containers/StaffUI/Services'),
    loading: LoadingComponent,
  }),
  TelcoInaBoxSimCards: Loadable({
    loader: () => import('./containers/StaffUI/MobileTelcoInABox/SimCards'),
    loading: LoadingComponent,
  }),
  CellularSIMCards: Loadable({
    loader: () => import('./containers/StaffUI/CellularSIMCards'),
    loading: LoadingComponent,
  }),
  CellularSIMCardsChangeLogs: Loadable({
    loader: () => import('./containers/StaffUI/CellularSIMCardChangeLogs'),
    loading: LoadingComponent,
  }),
  ManagedServices: Loadable({
    loader: () => import('./containers/StaffUI/ManagedServices'),
    loading: LoadingComponent,
  }),

  /**
   * Client management.
   */
  Companies: Loadable({
    loader: () => import('./containers/StaffUI/Companies'),
    loading: LoadingComponent,
  }),
  Contacts: Loadable({
    loader: () => import('./containers/StaffUI/Contacts'),
    loading: LoadingComponent,
  }),

  /**
   * Change management.
   */
  Changes: Loadable({
    loader: () => import('./containers/StaffUI/Changes'),
    loading: LoadingComponent,
  }),
  ChangeRequests: Loadable({
    loader: () => import('./containers/StaffUI/ChangeRequests'),
    loading: LoadingComponent,
  }),
  ChangeRequestProxyRedirect: Loadable({
    loader: () => import('./containers/StaffUI/ChangeRequestProxyRedirect'),
    loading: LoadingComponent,
  }),
  ChangeRequestPreview: Loadable({
    loader: () => import('./containers/StaffUI/ChangeRequestPreview'),
    loading: LoadingComponent,
  }),

  /**
   * Orders management.
   */
  Orders: Loadable({
    loader: () => import('./containers/StaffUI/Orders'),
    loading: LoadingComponent,
  }),

  /**
   * Reports.
   */
  InvoicesReport: Loadable({
    loader: () => import('./containers/StaffUI/Reports/Invoices'),
    loading: LoadingComponent,
  }),

  /**
   * Location search.
   */
  LocationSearch: Loadable({
    loader: () => import('./containers/StaffUI/LocationSearch'),
    loading: LoadingComponent,
  }),

  /**
   * Voice billing.
   */
  Jurisdictions: Loadable({
    loader: () => import('./containers/StaffUI/VoiceBilling/Jurisdictions'),
    loading: LoadingComponent,
  }),
  SIPTrunks: Loadable({
    loader: () => import('./containers/StaffUI/VoiceBilling/SIPTrunks'),
    loading: LoadingComponent,
  }),
  PhoneNumberRanges: Loadable({
    loader: () => import('./containers/StaffUI/VoiceBilling/PhoneNumberRanges'),
    loading: LoadingComponent,
  }),
  BaseRateCards: Loadable({
    loader: () => import('./containers/StaffUI/VoiceBilling/BaseRateCards'),
    loading: LoadingComponent,
  }),
  CustomRateCards: Loadable({
    loader: () => import('./containers/StaffUI/VoiceBilling/CustomRateCards'),
    loading: LoadingComponent,
  }),
  ClientReports: Loadable({
    loader: () => import('./containers/StaffUI/VoiceBilling/ClientReports'),
    loading: LoadingComponent,
  }),
  ReachReports: Loadable({
    loader: () => import('./containers/StaffUI/VoiceBilling/ReachReports'),
    loading: LoadingComponent,
  }),
  ConsolidatedReports: Loadable({
    loader: () =>
      import('./containers/StaffUI/VoiceBilling/ConsolidatedReports'),
    loading: LoadingComponent,
  }),
  DiscountAgreements: Loadable({
    loader: () =>
      import('./containers/StaffUI/VoiceBilling/DiscountAgreements'),
    loading: LoadingComponent,
  }),
  ReportRuns: Loadable({
    loader: () => import('./containers/StaffUI/VoiceBilling/ReportRuns'),
    loading: LoadingComponent,
  }),
  AnswerPoints: Loadable({
    loader: () => import('./containers/StaffUI/VoiceBilling/AnswerPoints'),
    loading: LoadingComponent,
  }),

  /**
   * Admin.
   */
  Carriers: Loadable({
    loader: () => import('./containers/StaffUI/Admin/Carriers'),
    loading: LoadingComponent,
  }),
  MaintenanceOrganizations: Loadable({
    loader: () => import('./containers/StaffUI/Admin/MaintenanceOrganizations'),
    loading: LoadingComponent,
  }),
  ServiceCategories: Loadable({
    loader: () => import('./containers/StaffUI/Admin/ServiceCategories'),
    loading: LoadingComponent,
  }),
  ServiceTypes: Loadable({
    loader: () => import('./containers/StaffUI/Admin/ServiceTypes'),
    loading: LoadingComponent,
  }),
  Bandwidths: Loadable({
    loader: () => import('./containers/StaffUI/Admin/Bandwidths'),
    loading: LoadingComponent,
  }),
  CircuitTypes: Loadable({
    loader: () => import('./containers/StaffUI/Admin/CircuitTypes'),
    loading: LoadingComponent,
  }),
  DeviceManufacturers: Loadable({
    loader: () => import('./containers/StaffUI/Admin/DeviceManufacturers'),
    loading: LoadingComponent,
  }),
  DeviceTags: Loadable({
    loader: () => import('./containers/StaffUI/Admin/DeviceTags'),
    loading: LoadingComponent,
  }),
  CustomerProvisioningStatuses: Loadable({
    loader: () =>
      import('./containers/StaffUI/Admin/Provisioning/CustomerStatuses'),
    loading: LoadingComponent,
  }),
  ServiceMessages: Loadable({
    loader: () => import('./containers/StaffUI/Admin/ServiceMessages'),
    loading: LoadingComponent,
  }),
  EmailSettings: Loadable({
    loader: () =>
      import('./containers/StaffUI/Admin/Notifications/EmailSettings'),
    loading: LoadingComponent,
  }),
  Emails: Loadable({
    loader: () => import('./containers/StaffUI/Admin/Notifications/Emails'),
    loading: LoadingComponent,
  }),
  Users: Loadable({
    loader: () => import('./containers/StaffUI/Admin/Users'),
    loading: LoadingComponent,
  }),
  UserGroups: Loadable({
    loader: () => import('./containers/StaffUI/Admin/Permissions/UserGroups'),
    loading: LoadingComponent,
  }),
  Permissions: Loadable({
    loader: () => import('./containers/StaffUI/Admin/Permissions'),
    loading: LoadingComponent,
  }),
  PermissionsSets: Loadable({
    loader: () =>
      import('./containers/StaffUI/Admin/Permissions/PermissionsSets'),
    loading: LoadingComponent,
  }),
  HandoverDocumentTemplates: Loadable({
    loader: () =>
      import('./containers/StaffUI/Admin/CustomerHandover/DocumentTemplates'),
    loading: LoadingComponent,
  }),
  InterfaceTypes: Loadable({
    loader: () => import('./containers/StaffUI/Admin/InterfaceTypes'),
    loading: LoadingComponent,
  }),
  InterfaceConfigurations: Loadable({
    loader: () => import('./containers/StaffUI/Admin/InterfaceConfigurations'),
    loading: LoadingComponent,
  }),
  WorkPlanTemplates: Loadable({
    loader: () => import('./containers/StaffUI/Admin/WorkPlanTemplates'),
    loading: LoadingComponent,
  }),
  ConnectWiseSyncEvents: Loadable({
    loader: () => import('./containers/StaffUI/Admin/ConnectWise/SyncEvents'),
    loading: LoadingComponent,
  }),
  CallTypes: Loadable({
    loader: () => import('./containers/StaffUI/Admin/VoiceBilling/CallTypes'),
    loading: LoadingComponent,
  }),
  CallSubtypes: Loadable({
    loader: () =>
      import('./containers/StaffUI/Admin/VoiceBilling/CallSubtypes'),
    loading: LoadingComponent,
  }),
  VoiceCodecs: Loadable({
    loader: () => import('./containers/StaffUI/Admin/VoiceBilling/VoiceCodecs'),
    loading: LoadingComponent,
  }),
  DeviceCategories: Loadable({
    loader: () => import('./containers/StaffUI/Admin/DeviceCategories'),
    loading: LoadingComponent,
  }),
  RateBundles: Loadable({
    loader: () => import('./containers/StaffUI/VoiceBilling/RateBundles'),
    loading: LoadingComponent,
  }),

  /**
   * Forms constructor.
   */
  FormBuilderTemplates: Loadable({
    loader: () =>
      import('./containers/StaffUI/Admin/FormBuilder/ConstructedFormTemplates'),
    loading: LoadingComponent,
  }),
  FormBuilderForms: Loadable({
    loader: () =>
      import('./containers/StaffUI/Admin/FormBuilder/ConstructedForms'),
    loading: LoadingComponent,
  }),

  /**
   * Operations.
   */
  CircuitRelocation: Loadable({
    loader: () =>
      import('./containers/StaffUI/Admin/Operations/CircuitRelocation'),
    loading: LoadingComponent,
  }),
  TagManagement: Loadable({
    loader: () => import('./containers/StaffUI/Admin/Operations/TagManagement'),
    loading: LoadingComponent,
  }),

  /**
   * Work plans.
   */
  WorkPlans: Loadable({
    loader: () => import('./containers/StaffUI/WorkPlans'),
    loading: LoadingComponent,
  }),

  /**
   * Dashboard and misc.
   */
  Dashboard: Loadable({
    loader: () => import('./containers/StaffUI/Dashboard'),
    loading: LoadingComponent,
  }),

  /**
   * User invitations.
   */
  AcceptUserInvitation: Loadable({
    loader: () => import('./containers/Shared/AcceptUserInvitation'),
    loading: LoadingComponent,
  }),
}

/********************************
 * END OF SUPERADMIN APPLICATION
 ********************************/

/********************************
 * CUSTOMER APPLICATION
 ********************************/

const CustomerApplication = {
  CompanyProfile: Loadable({
    loader: () => import('./containers/CustomerUI/CompanyProfile'),
    loading: LoadingComponent,
  }),
  Dashboard: Loadable({
    loader: () => import('./containers/CustomerUI/Dashboard'),
    loading: LoadingComponent,
  }),
  OrderDeliveryPage: Loadable({
    loader: () =>
      import('./containers/CustomerUI/ServiceDelivery/OrderDeliveryPage'),
    loading: LoadingComponent,
  }),
  OrderDeliveryDetailsPage: Loadable({
    loader: () =>
      import(
        './containers/CustomerUI/ServiceDelivery/OrderDeliveryDetailsPage'
      ),
    loading: LoadingComponent,
  }),
  ActiveServicesListPage: Loadable({
    loader: () =>
      import('./containers/CustomerUI/Services/ActiveServicesListPage'),
    loading: LoadingComponent,
  }),
  ActiveServiceDetailsPage: Loadable({
    loader: () =>
      import('./containers/CustomerUI/Services/ActiveServiceDetailsPage'),
    loading: LoadingComponent,
  }),
  TelcoInaBoxSimCardsListPage: Loadable({
    loader: () =>
      import('./containers/CustomerUI/MobileVoice/TelcoInaBoxSimCardsList'),
    loading: LoadingComponent,
  }),
  TelcoInaBoxSimCardDetails: Loadable({
    loader: () =>
      import('./containers/CustomerUI/MobileVoice/TelcoInaBoxSimCardDetails'),
    loading: LoadingComponent,
  }),
  ManagedServicesListPage: Loadable({
    loader: () =>
      import('./containers/CustomerUI/ManagedServices/ManagedServicesListPage'),
    loading: LoadingComponent,
  }),
  ManagedServiceDetailsPage: Loadable({
    loader: () =>
      import(
        './containers/CustomerUI/ManagedServices/ManagedServiceDetailsPage'
      ),
    loading: LoadingComponent,
  }),
  ManagedDeviceDetailsPage: Loadable({
    loader: () =>
      import(
        './containers/CustomerUI/ManagedServices/ManagedDeviceDetailsPage'
      ),
    loading: LoadingComponent,
  }),
  ChangesListPage: Loadable({
    loader: () => import('./containers/CustomerUI/Changes/ChangesListPage'),
    loading: LoadingComponent,
  }),
  ChangeDetailsPage: Loadable({
    loader: () => import('./containers/CustomerUI/Changes/ChangeDetailsPage'),
    loading: LoadingComponent,
  }),
  ChangeRequestsListPage: Loadable({
    loader: () =>
      import('./containers/CustomerUI/ChangeRequests/ChangeRequestsListPage'),
    loading: LoadingComponent,
  }),
  ChangeRequestDetailsPage: Loadable({
    loader: () =>
      import('./containers/CustomerUI/ChangeRequests/ChangeRequestDetailsPage'),
    loading: LoadingComponent,
  }),
  ChangeRequestProxyRedirect: Loadable({
    loader: () =>
      import(
        './containers/CustomerUI/ChangeRequests/ChangeRequestProxyRedirect'
      ),
    loading: LoadingComponent,
  }),
  SupportTicketsPage: Loadable({
    loader: () => import('./containers/CustomerUI/Support/SupportTicketsPage'),
    loading: LoadingComponent,
  }),
  OpenTicketPage: Loadable({
    loader: () => import('./containers/CustomerUI/Support/OpenTicketPage'),
    loading: LoadingComponent,
  }),
  TicketDetailsPage: Loadable({
    loader: () => import('./containers/CustomerUI/Support/TicketDetailsPage'),
    loading: LoadingComponent,
  }),
  MyNetwork: Loadable({
    loader: () => import('./containers/CustomerUI/MyNetwork'),
    loading: LoadingComponent,
  }),
  ReportsListPage: Loadable({
    loader: () => import('./containers/CustomerUI/Reports'),
    loading: LoadingComponent,
  }),
  ServicesReport: Loadable({
    loader: () => import('./containers/CustomerUI/Reports/Services'),
    loading: LoadingComponent,
  }),
  SupportTicketsReport: Loadable({
    loader: () => import('./containers/CustomerUI/Reports/SupportTickets'),
    loading: LoadingComponent,
  }),
  ChangeManagementReport: Loadable({
    loader: () => import('./containers/CustomerUI/Reports/ChangeManagement'),
    loading: LoadingComponent,
  }),
  ManagedDevicesReport: Loadable({
    loader: () => import('./containers/CustomerUI/Reports/ManagedDevices'),
    loading: LoadingComponent,
  }),
  ManagedServicesDeviceAvailabilityReport: Loadable({
    loader: () =>
      import(
        './containers/CustomerUI/Reports/ManagedServicesDeviceAvailability'
      ),
    loading: LoadingComponent,
  }),
  ServiceAvailabilityReport: Loadable({
    loader: () => import('./containers/CustomerUI/Reports/ServiceAvailability'),
    loading: LoadingComponent,
  }),
  AlertHistoryReport: Loadable({
    loader: () => import('./containers/CustomerUI/Reports/AlertHistory'),
    loading: LoadingComponent,
  }),

  DDoS: Loadable({
    loader: () => import('./containers/CustomerUI/DDoS'),
    loading: LoadingComponent,
  }),

  AcceptUserInvitation: Loadable({
    loader: () => import('./containers/Shared/AcceptUserInvitation'),
    loading: LoadingComponent,
  }),
}

const PublicApplication = {
  ConstructedFormPage: Loadable({
    loader: () =>
      import('./containers/PublicUI/FormBuilder/ConstructedFormPage'),
    loading: LoadingComponent,
  }),
  ChangeRequestApproval: Loadable({
    loader: () => import('./containers/PublicUI/ChangeRequestApproval'),
    loading: LoadingComponent,
  }),
  AcceptUserInvitation: Loadable({
    loader: () => import('./containers/PublicUI/AcceptUserInvitation'),
    loading: LoadingComponent,
  }),
}

/********************************
 * END OF CUSTOMER APPLICATION
 ********************************/

export const superAdminRoutes = (
  <Switch>
    <Route
      exact
      path={URLS.auth.login.route}
      component={() => {
        return <Redirect to={URLS.dashboard.index.buildPath()} />
      }}
    />

    <PrivateRoute
      exact
      path={URLS.auth.authCallback.route}
      component={AuthCallback}
    />

    <PrivateRoute
      exact
      path={URLS.account.logout.route}
      component={LogoutPage}
    />

    <PrivateRoute
      exact
      path={URLS.dashboard.index.route}
      component={SuperAdminApplication.Dashboard}
    />

    {/* Service Management */}
    <PrivateRoute
      exact
      path={URLS.serviceManagement.locations.list.route}
      component={SuperAdminApplication.Locations}
    />
    <PrivateRoute
      exact
      path={URLS.serviceManagement.circuits.list.route}
      component={SuperAdminApplication.Circuits}
    />
    <PrivateRoute
      exact
      path={URLS.serviceManagement.devicesAndContracts.devices.list.route}
      component={SuperAdminApplication.Devices}
    />
    <PrivateRoute
      exact
      path={
        URLS.serviceManagement.devicesAndContracts.maintenanceContracts.list
          .route
      }
      component={SuperAdminApplication.MaintenanceContracts}
    />
    <PrivateRoute
      exact
      path={URLS.serviceManagement.services.list.route}
      component={SuperAdminApplication.Services}
    />
    <PrivateRoute
      exact
      path={URLS.serviceManagement.mobile.cellularSIMCards.list.route}
      component={SuperAdminApplication.CellularSIMCards}
    />
    <PrivateRoute
      exact
      path={URLS.serviceManagement.mobile.cellularSIMCardChangeLogs.list.route}
      component={SuperAdminApplication.CellularSIMCardsChangeLogs}
    />
    <PrivateRoute
      exact
      path={URLS.serviceManagement.mobileVoice.simCards.list.route}
      component={SuperAdminApplication.TelcoInaBoxSimCards}
    />
    <PrivateRoute
      exact
      path={URLS.serviceManagement.managedServices.list.route}
      component={SuperAdminApplication.ManagedServices}
    />

    {/* Client Management */}
    <PrivateRoute
      path={URLS.clientManagement.companies.list.route}
      component={SuperAdminApplication.Companies}
    />
    <PrivateRoute
      exact
      path={URLS.clientManagement.contacts.list.route}
      component={SuperAdminApplication.Contacts}
    />

    {/* Change Management */}
    <PrivateRoute
      exact
      path={URLS.changeManagement.changes.list.route}
      component={SuperAdminApplication.Changes}
    />
    <PrivateRoute
      exact
      path={URLS.changeManagement.requests.list.route}
      component={SuperAdminApplication.ChangeRequests}
    />
    <Route
      exact
      path={URLS.publicUI.changeManagement.requests.detail.route}
      component={SuperAdminApplication.ChangeRequestProxyRedirect}
    />
    <Route
      exact
      path={URLS.changeManagement.requests.preview.route}
      component={SuperAdminApplication.ChangeRequestPreview}
    />

    {/* Orders Management */}
    <PrivateRoute
      exact
      path={URLS.orderManagement.orders.list.route}
      component={SuperAdminApplication.Orders}
    />
    <PrivateRoute
      exact
      path={URLS.orderManagement.workflows.index.route}
      component={SuperAdminApplication.WorkPlans}
    />

    {/* Reports */}
    <PrivateRoute
      exact
      path={URLS.reports.invoices.route}
      component={SuperAdminApplication.InvoicesReport}
    />

    {/*Location search */}
    <PrivateRoute
      exact
      path={URLS.locationSearch.index.route}
      component={SuperAdminApplication.LocationSearch}
    />

    {/* Voice Billing */}
    <PrivateRoute
      exact
      path={URLS.voiceBilling.jurisdictions.list.route}
      component={SuperAdminApplication.Jurisdictions}
    />
    <PrivateRoute
      exact
      path={URLS.voiceBilling.SIPTrunks.list.route}
      component={SuperAdminApplication.SIPTrunks}
    />
    <PrivateRoute
      exact
      path={URLS.voiceBilling.phoneNumberRanges.list.route}
      component={SuperAdminApplication.PhoneNumberRanges}
    />
    <PrivateRoute
      exact
      path={URLS.voiceBilling.baseRateCards.list.route}
      component={SuperAdminApplication.BaseRateCards}
    />
    <PrivateRoute
      exact
      path={URLS.voiceBilling.customRateCards.list.route}
      component={SuperAdminApplication.CustomRateCards}
    />
    <PrivateRoute
      exact
      path={URLS.voiceBilling.clientReports.list.route}
      component={SuperAdminApplication.ClientReports}
    />
    <PrivateRoute
      exact
      path={URLS.voiceBilling.reachReports.list.route}
      component={SuperAdminApplication.ReachReports}
    />
    <PrivateRoute
      exact
      path={URLS.voiceBilling.consolidatedReports.list.route}
      component={SuperAdminApplication.ConsolidatedReports}
    />
    <PrivateRoute
      exact
      path={URLS.voiceBilling.discountAgreements.list.route}
      component={SuperAdminApplication.DiscountAgreements}
    />
    <PrivateRoute
      exact
      path={URLS.voiceBilling.rateBundles.list.route}
      component={SuperAdminApplication.RateBundles}
    />
    <PrivateRoute
      exact
      path={URLS.voiceBilling.reportRuns.list.route}
      component={SuperAdminApplication.ReportRuns}
    />
    <PrivateRoute
      exact
      path={URLS.voiceBilling.answerPoints.list.route}
      component={SuperAdminApplication.AnswerPoints}
    />

    {/* Forms Constructor */}
    <PrivateRoute
      exact
      path={URLS.admin.formBuilder.templates.list.route}
      component={SuperAdminApplication.FormBuilderTemplates}
    />
    <PrivateRoute
      exact
      path={URLS.admin.formBuilder.forms.list.route}
      component={SuperAdminApplication.FormBuilderForms}
    />

    {/* Operations */}
    <PrivateRoute
      exact
      path={URLS.admin.operations.circuitRelocation.index.route}
      component={SuperAdminApplication.CircuitRelocation}
    />
    <PrivateRoute
      exact
      path={URLS.admin.operations.tagManagement.index.route}
      component={SuperAdminApplication.TagManagement}
    />

    {/* Vendor Management */}
    <PrivateRoute
      exact
      path={URLS.admin.vendorManagement.carriers.list.route}
      component={SuperAdminApplication.Carriers}
    />
    <PrivateRoute
      exact
      path={URLS.admin.vendorManagement.maintenanceOrganizations.list.route}
      component={SuperAdminApplication.MaintenanceOrganizations}
    />

    {/* Circuits and Devices */}
    <PrivateRoute
      exact
      path={URLS.admin.circuitsAndDevices.bandwidths.list.route}
      component={SuperAdminApplication.Bandwidths}
    />
    <PrivateRoute
      exact
      path={URLS.admin.circuitsAndDevices.circuitTypes.list.route}
      component={SuperAdminApplication.CircuitTypes}
    />
    <PrivateRoute
      exact
      path={URLS.admin.circuitsAndDevices.deviceManufacturers.list.route}
      component={SuperAdminApplication.DeviceManufacturers}
    />
    <PrivateRoute
      exact
      path={URLS.admin.circuitsAndDevices.deviceTags.list.route}
      component={SuperAdminApplication.DeviceTags}
    />
    <PrivateRoute
      exact
      path={URLS.admin.circuitsAndDevices.interfaceTypes.list.route}
      component={SuperAdminApplication.InterfaceTypes}
    />
    <PrivateRoute
      exact
      path={URLS.admin.circuitsAndDevices.interfaceConfigurations.list.route}
      component={SuperAdminApplication.InterfaceConfigurations}
    />
    <PrivateRoute
      exact
      path={URLS.admin.circuitsAndDevices.deviceCategories.list.route}
      component={SuperAdminApplication.DeviceCategories}
    />

    {/* Provisioning */}
    <PrivateRoute
      exact
      path={URLS.admin.provisioning.customerStatuses.list.route}
      component={SuperAdminApplication.CustomerProvisioningStatuses}
    />

    {/* Service Messages */}
    <PrivateRoute
      exact
      path={URLS.admin.serviceMessages.list.route}
      component={SuperAdminApplication.ServiceMessages}
    />

    {/* Email Settings */}
    <PrivateRoute
      exact
      path={URLS.admin.emailSettings.emailSettings.list.route}
      component={SuperAdminApplication.EmailSettings}
    />
    <PrivateRoute
      exact
      path={URLS.admin.emailSettings.emails.list.route}
      component={SuperAdminApplication.Emails}
    />

    {/* Users and Groups */}
    <PrivateRoute
      exact
      path={URLS.admin.userAndGroups.users.list.route}
      component={SuperAdminApplication.Users}
    />
    <PrivateRoute
      exact
      path={URLS.admin.userAndGroups.permissions.list.route}
      component={SuperAdminApplication.Permissions}
    />
    <PrivateRoute
      exact
      path={URLS.admin.userAndGroups.permissionsSets.list.route}
      component={SuperAdminApplication.PermissionsSets}
    />
    <PrivateRoute
      exact
      path={URLS.admin.userAndGroups.userGroups.list.route}
      component={SuperAdminApplication.UserGroups}
    />

    {/* Product Management */}
    <PrivateRoute
      exact
      path={URLS.admin.productManagement.serviceCategories.list.route}
      component={SuperAdminApplication.ServiceCategories}
    />
    <PrivateRoute
      exact
      path={URLS.admin.productManagement.serviceTypes.list.route}
      component={SuperAdminApplication.ServiceTypes}
    />
    <PrivateRoute
      exact
      path={URLS.admin.productManagement.handoverDocumentTemplates.list.route}
      component={SuperAdminApplication.HandoverDocumentTemplates}
    />
    <PrivateRoute
      exact
      path={URLS.admin.productManagement.workPlanTemplates.list.route}
      component={SuperAdminApplication.WorkPlanTemplates}
    />

    {/* ConnectWise */}
    <PrivateRoute
      exact
      path={URLS.admin.connectwise.syncEvents.list.route}
      component={SuperAdminApplication.ConnectWiseSyncEvents}
    />

    {/* Voice Billing */}
    <PrivateRoute
      exact
      path={URLS.admin.voiceBilling.callTypes.list.route}
      component={SuperAdminApplication.CallTypes}
    />
    <PrivateRoute
      exact
      path={URLS.admin.voiceBilling.callSubtypes.list.route}
      component={SuperAdminApplication.CallSubtypes}
    />
    <PrivateRoute
      exact
      path={URLS.admin.voiceBilling.voiceCodecs.list.route}
      component={SuperAdminApplication.VoiceCodecs}
    />

    <Route
      exact
      path={URLS.userInvitations.acceptInvitation.route}
      component={SuperAdminApplication.AcceptUserInvitation}
    />

    <PrivateRoute component={PageNotFound} />
  </Switch>
)

export const customerRoutes = (
  <Switch>
    <Route
      exact
      path={URLS.auth.login.route}
      component={() => {
        return <Redirect to={URLS.dashboard.index.buildPath()} />
      }}
    />

    <PrivateRoute
      exact
      path={URLS.auth.authCallback.route}
      component={AuthCallback}
    />

    <PrivateRoute
      exact
      path={URLS.account.logout.route}
      component={LogoutPage}
    />
    <PrivateRoute
      exact
      path={URLS.customerUI.companyProfile.index.route}
      component={CustomerApplication.CompanyProfile}
    />

    <PrivateRoute
      exact
      path={URLS.customerUI.dashboard.index.route}
      component={CustomerApplication.Dashboard}
    />

    <PrivateRoute
      exact
      path={URLS.customerUI.serviceDelivery.index.route}
      component={CustomerApplication.OrderDeliveryPage}
    />
    <PrivateRoute
      exact
      path={URLS.customerUI.serviceDelivery.detail.route}
      component={CustomerApplication.OrderDeliveryDetailsPage}
    />

    <PrivateRoute
      exact
      path={URLS.customerUI.services.index.route}
      component={CustomerApplication.ActiveServicesListPage}
    />
    <PrivateRoute
      exact
      path={URLS.customerUI.services.detail.route}
      component={CustomerApplication.ActiveServiceDetailsPage}
    />
    <PrivateRoute
      exact
      path={URLS.customerUI.mobileVoice.index.route}
      component={CustomerApplication.TelcoInaBoxSimCardsListPage}
    />
    <PrivateRoute
      exact
      path={URLS.customerUI.mobileVoice.detail.route}
      component={CustomerApplication.TelcoInaBoxSimCardDetails}
    />

    <PrivateRoute
      exact
      path={URLS.customerUI.managedServices.index.route}
      component={CustomerApplication.ManagedServicesListPage}
    />
    <PrivateRoute
      exact
      path={URLS.customerUI.managedServices.detail.route}
      component={CustomerApplication.ManagedServiceDetailsPage}
    />
    <PrivateRoute
      exact
      path={URLS.customerUI.managedServices.devices.detail.route}
      component={CustomerApplication.ManagedDeviceDetailsPage}
    />

    <PrivateRoute
      exact
      path={URLS.customerUI.changeManagement.changes.index.route}
      component={CustomerApplication.ChangesListPage}
    />
    <PrivateRoute
      exact
      path={URLS.customerUI.changeManagement.changes.detail.route}
      component={CustomerApplication.ChangeDetailsPage}
    />

    <PrivateRoute
      exact
      path={URLS.customerUI.changeManagement.requests.index.route}
      component={CustomerApplication.ChangeRequestsListPage}
    />
    <PrivateRoute
      exact
      path={URLS.customerUI.changeManagement.requests.detail.route}
      component={CustomerApplication.ChangeRequestDetailsPage}
    />
    <Route
      exact
      path={URLS.publicUI.changeManagement.requests.detail.route}
      component={CustomerApplication.ChangeRequestProxyRedirect}
    />

    <PrivateRoute
      exact
      path={URLS.customerUI.support.index.route}
      component={CustomerApplication.SupportTicketsPage}
    />
    <PrivateRoute
      exact
      path={URLS.customerUI.support.openTicket.route}
      component={CustomerApplication.OpenTicketPage}
    />
    <PrivateRoute
      exact
      path={URLS.customerUI.support.detail.route}
      component={CustomerApplication.TicketDetailsPage}
    />

    <PrivateRoute
      exact
      path={URLS.customerUI.monitoring.myNetwork.index.route}
      component={CustomerApplication.MyNetwork}
    />
    {/*<PrivateRoute
      path={URLS.customerUI.monitoring.ddos.index.route}
      component={CustomerApplication.DDoS}
    />*/}

    <PrivateRoute
      exact
      path={URLS.customerUI.reports.index.route}
      component={CustomerApplication.ReportsListPage}
    />
    <PrivateRoute
      exact
      path={URLS.customerUI.reports.services.route}
      component={CustomerApplication.ServicesReport}
    />
    <PrivateRoute
      exact
      path={URLS.customerUI.reports.supportTickets.route}
      component={CustomerApplication.SupportTicketsReport}
    />
    <PrivateRoute
      exact
      path={URLS.customerUI.reports.changeManagement.route}
      component={CustomerApplication.ChangeManagementReport}
    />
    <PrivateRoute
      exact
      path={URLS.customerUI.reports.managedDevices.route}
      component={CustomerApplication.ManagedDevicesReport}
    />
    <PrivateRoute
      exact
      path={URLS.customerUI.reports.managedServicesDeviceAvailability.route}
      component={CustomerApplication.ManagedServicesDeviceAvailabilityReport}
    />
    <PrivateRoute
      exact
      path={URLS.customerUI.reports.serviceAvailability.route}
      component={CustomerApplication.ServiceAvailabilityReport}
    />
    <PrivateRoute
      exact
      path={URLS.customerUI.reports.alertHistory.route}
      component={CustomerApplication.AlertHistoryReport}
    />

    <PrivateRoute
      exact
      path={URLS.customerUI.forms.detail.route}
      component={PublicApplication.ConstructedFormPage}
    />

    <Route
      exact
      path={URLS.customerUI.userInvitations.acceptInvitation.route}
      component={CustomerApplication.AcceptUserInvitation}
    />

    <PrivateRoute component={PageNotFound} />
  </Switch>
)

export const noAccessRoutes = (
  <Switch>
    <Route
      exact
      path={URLS.auth.login.route}
      component={() => {
        return <Redirect to={URLS.dashboard.index.buildPath()} />
      }}
    />
    <PrivateRoute
      exact
      path={URLS.account.logout.route}
      component={LogoutPage}
    />
    <PrivateRoute
      path={URLS.dashboard.index.route}
      component={AccountNotSetYetPage}
    />
    <PrivateRoute component={PageNotFound} />
  </Switch>
)

export const publicRoutes = (
  <Switch>
    <Route
      exact
      path={URLS.dashboard.index.route}
      component={() => {
        return <Redirect to={URLS.auth.login.buildPath()} />
      }}
    />
    <Route exact path={URLS.auth.login.route} component={LoginPage} />
    <Route
      exact
      path={URLS.publicUI.forms.detail.route}
      component={PublicApplication.ConstructedFormPage}
    />
    <Route
      exact
      path={URLS.publicUI.changeManagement.requests.detail.route}
      component={PublicApplication.ChangeRequestApproval}
    />
    <Route
      exact
      path={URLS.publicUI.userInvitations.acceptInvitation.route}
      component={PublicApplication.AcceptUserInvitation}
    />
    <Route
      exact
      path={URLS.account.logout.route}
      component={() => {
        return <Redirect to={URLS.auth.login.buildPath()} />
      }}
    />
    <Route component={PageNotFound} />
  </Switch>
)
